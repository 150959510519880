import { useEffect, useRef, useState } from "react";

import Avatar from "../assets/Avatar.png";
import {
  capitalizeFirstLetter,
  conversationTopics,
  getIcon,
  isMobile,
  loading,
  serviceAndText,
} from "../utilities/Functions";
import { Button, Image, Nav, Stack } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useGetDoc, useGetDocs, useGetFile } from "../utilities/CustomHooks";

import "./PageStyles.css";
import Education from "../components/Education";

const CreatorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;

  var user = JSON.parse(sessionStorage.getItem("token"));
  var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  if (!userInfo && user) {
    userInfo = { userId: user.uid, name: user.displayName };
  }
  const [showEdu, setShowEdu] = useState();
  const [eduState, setEduState] = useState();

  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();

  const [edit, setEdit] = useState(false);

  const { data: creatorInfo } = useGetDoc(
    "Creator",
    id,
    [id, updatePending],
    setPending,
    setError
  );

  const [uiCreatorServices, setUiCreatorServices] = useState(null);
  const { fbDocs: creatorServices } = useGetDocs(
    `Creator/${id}/Products`,
    [id, updatePending],
    setPending,
    setError
  );

  const [fileName, setFileName] = useState();
  const [dependant, setDependant] = useState([null, updatePending]);
  const { file: photo, setFile: setPhoto } = useGetFile(
    fileName,
    dependant,
    setPending,
    setError
  );

  const servicesArray = serviceAndText();

  // check if selected creator is user's creator
  useEffect(() => {
    if (userInfo && userInfo.asCreator === id && !edit) {
      setEdit(true);
    }
  }, [id]);

  //  get photo with creatorInfo.userId and set photo dependencies
  useEffect(() => {
    if (creatorInfo && creatorInfo.userId) {
      setFileName(
        "user" +
          creatorInfo.userId +
          "/" +
          creatorInfo.userId +
          "ProfilePicture"
      );
      setDependant([creatorInfo.userId, updatePending]);
    }
  }, [creatorInfo]);

  // set dummy photo if photo is null
  useEffect(() => {
    if (!photo) {
      setPhoto(Avatar);
    }
  }, [photo]);

  // set services appropriate for screen
  useEffect(() => {
    if (creatorServices) {
      setUiServices();
    }
  }, [creatorServices]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function setUiServices() {
    let uiServices = [];
    let found;

    creatorServices.forEach((original) => {
      found = servicesArray.find(
        (array) => original.typeOfService === array.typeOfService
      );
      if (found !== undefined) {
        uiServices.push(original);
      }
    });
    setUiCreatorServices([...uiServices]);
  }

  function handleServiceClick(service) {
    // if (edit) {
    //   // creators won't use service for themselves
    //   return;
    // }
    if (!user) {
      navigate("/login", { state: { fromInside: true } });
      return;
    }
    var state = {
      userId: user.uid,
      creatorId: creatorInfo.id,
      service: service,
      convoId: null,
      loggedBy: "user",
    };
    var isQuestion = service.typeOfService.search("Question");
    var isCached;

    // check if service education cached
    if (isQuestion > 0) {
      // question cache
      isCached = JSON.parse(sessionStorage.getItem("isQuestionEduSeen"));
    } else {
      // review cache
      isCached = JSON.parse(sessionStorage.getItem("isRevievEduSeen"));
    }

    if (isCached || isMobile) {
      // service type education seen, navigate to question/review
      navigate("/inquiry-detail-page", {
        state: state,
      });
    } else {
      // service type education not seen, education pop up
      setEduState(state);
      showEduHandler(service, state);
    }
  }

  function showEduHandler(service, state) {
    if (!state || state === undefined) {
      setEduState({
        service: service,
      });
    }
    setShowEdu(true);
  }

  function eduCallBack(eduState, isCompleted) {
    setShowEdu(false);
    if (isCompleted && eduState.creatorId) {
      navigate("/inquiry-detail-page", {
        state: eduState,
      });
    }
  }

  // page layout - stack
  function setPageLayout() {
    if (pending) {
      // return loading();
    } else if (creatorInfo === null) {
      // error handling - creator not found
    } else if (showEdu && !isMobile) {
      // show education pop-up
      return <Education eduState={eduState} callBack={eduCallBack} />;
    } else {
      return (
        <Stack gap={3} className="col-12">
          {/* bio - rating */}
          <div className="row">
            {/* bio */}
            <Stack gap={3} className="col col-xs-12 col-md-6 mb-2 mb-md-0">
              <Stack direction="horizontal">
                {photo && (
                  <Image src={photo} roundedCircle className="profile-photo" />
                )}

                {/* bio display - edit button*/}
                {edit && (
                  <Button
                    className="align-self-start"
                    variant="outline-secondary"
                    as={NavLink}
                    to="/creator-page-edit"
                    state={{ id: userInfo.asCreator, section: "bio" }}
                  >
                    <div>Edit</div>
                  </Button>
                )}
              </Stack>
              <div className="h3 fw-bold">{creatorInfo.creatorName}</div>
              <div className="p1">{creatorInfo.bio}</div>
            </Stack>

            {/* *********************************************** */}

            {/* seperator */}
            {/* vr - br xs-sm */}
            <div
              className={
                creatorInfo.numberOfJobsCompleted !== null &&
                creatorInfo.numberOfJobsCompleted > 9
                  ? "d-none d-md-flex flex-row col col-1 "
                  : "d-none d-md-flex col col-md-6"
              }
            >
              <div
                className={
                  creatorInfo.numberOfJobsCompleted !== null &&
                  creatorInfo.numberOfJobsCompleted > 9
                    ? "vr"
                    : "d-none"
                }
              />
            </div>
            {/* hr - br > sm */}
            <hr
              className={
                creatorInfo.numberOfJobsCompleted !== null &&
                creatorInfo.numberOfJobsCompleted > 9
                  ? "d-inline d-md-none my-4"
                  : "d-none"
              }
            />

            {/* rating */}
            {creatorInfo.numberOfJobsCompleted !== null &&
              creatorInfo.numberOfJobsCompleted > 9 && (
                <Stack
                  gap={3}
                  className="col col-xs-12 col-md-5 text-center justify-content-center"
                >
                  <div className="h1 fw-bold ">Rating</div>
                  <Stack gap={3} direction="horizontal">
                    <Stack gap={1} className="col-2">
                      <div className="d-flex justify-content-center justify-content-md-end">
                        <div className="col col-12 col-md-5 h1 fw-bold">
                          {creatorInfo.numberOfThumbsUp}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center justify-content-md-end">
                        <div className="col col-12 col-md-5 fs-5">
                          Thumbs Up
                        </div>
                      </div>
                    </Stack>
                    <div className="vr col-2" />
                    <Stack gap={1} className="col-2">
                      <div className="d-flex justify-content-center justify-content-md-start">
                        <div className="col col-12 col-md-5 h1 fw-bold">
                          {creatorInfo.numberOfJobsCompleted}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center justify-content-md-start">
                        <div className="col col-12 col-md-5 fs-5">
                          Responses
                        </div>
                      </div>
                    </Stack>
                  </Stack>
                </Stack>
              )}
          </div>

          {/* *********************************************** */}

          {/* links - insta, twitter */}
          {creatorInfo.links && <hr />}
          {creatorInfo.links && (
            <Stack gap={3} direction="horizontal">
              <Stack gap={2} className="col col-10 col-md-3">
                {creatorInfo.links.map((link) => (
                  <Nav.Link href={link.url} target="_blank" key={link.url}>
                    <Stack gap={2} direction="horizontal">
                      <Image
                        src={getIcon(link)}
                        className="creator-link-image"
                      />
                      <div className="p-2 fs-5">
                        {capitalizeFirstLetter(link.brand)}
                      </div>
                    </Stack>
                  </Nav.Link>
                ))}
              </Stack>

              {edit && (
                <Button
                  className="align-self-start"
                  variant="outline-secondary"
                  as={NavLink}
                  to="/creator-page-edit"
                  state={{ id: userInfo.asCreator, section: "link" }}
                >
                  <div>Edit</div>
                </Button>
              )}

              <div
                className={
                  edit
                    ? "d-none d-md-inline col col-md-8"
                    : "d-none d-md-inline col col-md-9"
                }
              />
            </Stack>
          )}

          {/* *********************************************** */}

          {/* services - topics */}
          {!creatorInfo.isOnVacation &&
            uiCreatorServices &&
            uiCreatorServices.find((service) => service.active === true) && (
              <>
                <hr />
                {/* header - edit */}
                <Stack gap={3} direction="horizontal">
                  {/* header */}
                  <Stack gap={2} className="col col-10 col-md-3">
                    <div className="h3 fw-bold">Conversation Topics</div>
                  </Stack>
                  {/* edit */}
                  {edit && (
                    <Button
                      className="align-self-start"
                      variant="outline-secondary"
                      as={NavLink}
                      to="/creator-page-edit"
                      state={{ id: userInfo.asCreator, section: "service" }}
                    >
                      <div>Edit</div>
                    </Button>
                  )}
                  <div
                    className={
                      edit
                        ? "d-none d-md-inline col col-md-8"
                        : "d-none d-md-inline col col-md-9"
                    }
                  />
                </Stack>

                {/* services - end-div */}
                <Stack gap={3} direction="horizontal">
                  {/* services 2 column (< md 1 column) layout */}
                  <div className="col col-12 col-md-9 row row-cols-2">
                    {uiCreatorServices
                      .filter((service) => service.active)
                      .map((service, index) => (
                        // service buton - info button
                        <Stack
                          gap={2}
                          direction="horizontal"
                          className="mb-3 col col-12 col-md-6"
                          key={service.typeOfService}
                        >
                          {/* service button */}
                          <Stack
                            gap={2}
                            className="col col-10 col-md-8 px-4 py-4 rounded-4 bg-r10_orange text-white"
                            role="button"
                            onClick={() => handleServiceClick(service)}
                          >
                            <div className="fw-bold">
                              {conversationTopics(service.typeOfService)}
                            </div>
                            <Stack gap={1} direction="horizontal">
                              <div className="">Price:</div>
                              <div className="fw-bold">
                                {`$${service.tokenPrice}`}
                              </div>
                            </Stack>
                          </Stack>
                          {/* info button */}
                          <div className="col-2">
                            <Button
                              variant="outline-secondary"
                              className="align-self-center"
                              onClick={() => showEduHandler(service)}
                            >
                              Info
                            </Button>
                          </div>
                          <div className="d-none d-md-inline col col-md-2" />
                        </Stack>
                      ))}
                  </div>
                  <div className="d-none d-md-inline col-3" />
                </Stack>
              </>
            )}
        </Stack>
      );
    }
  }

  return setPageLayout();
};

export default CreatorPage;
