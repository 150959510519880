import { BrowserRouter, Route, Router, Routes, useLocation } from "react-router-dom";
import "./App.css";
import ReactGA from 'react-ga4';
import R10Footer from "./components/R10Footer.js";
import MyNavbar from "./components/MyNavbar.js";
import AboutUs from "./pages/AboutUs.js";
import AboutUsMobile from "./pages/AboutUsMobile.js";
import AboutUsForCreators from "./pages/AboutUsForCreators.js";
import AboutUsForEndUser from "./pages/AboutUsForEndUser.js";
import Creators from "./pages/Creators.js";
import CreatorPage from "./pages/CreatorPage.js";
import Login from "./pages/Login.js";
// import Signup from "./pages/Signup.js";
import Settings from "./pages/Settings.js";
import UserProfile from "./pages/UserProfile.js";
import CreatorPageEdit from "./pages/CreatorPageEdit.js";
import TwitchLogin from "./pages/TwitchLogin.js";
import InquiryDetailPage from "./pages/InquiryDetailPage.js";
import InquiryAndHubList from "./pages/InquiryAndHubList.js";
import ApplyForSponsorship from "./pages/ApplyForSponsorship.js";
import ApplyToBeCreatorSeparated from "./pages/ApplyToBeCreatorSeparated.js";
import { useState } from "react";
import PaymentSuccess from "./pages/PaymentSuccess.js";
import CreatorsAll from "./pages/CreatorsAll.js";
import PaymentHub from "./pages/PaymentHub.js";
import ContactSupport from "./pages/ContactSupport.js";
import TermsConditions from "./pages/TermsConditions.js";
import CreatorTermsConditions from "./pages/CreatorTermsConditions.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import ReturnPolicy from "./pages/ReturnPolicy.js";
import WhyRated10 from "./pages/WhyRated10.js";
import FAQPage from "./pages/FAQPage.js";
import ReferralProgramPage from "./pages/ReferralProgramPage.js";
import DeleteAccountRequest from "./pages/DeleteAccountRequest.js";
import { useEffect } from "react";
import AccountLinkReauth from "./pages/AccountLinkReauth.js";
import AccountLinkReturn from "./pages/AccountLinkReturn.js";

function App() {
  const [searchParam, setSearchParam] = useState("");
  const [pageKey, setPageKey] = useState(0);

  ReactGA.initialize('G-7K23587348');

  return (
    <div className="app">
      <BrowserRouter>
        <MyNavbar
          setPageKey={setPageKey}
          searchParam={searchParam}
          setSearchParam={setSearchParam}
        />
        <p className="body">
          {/* <div className="mx-3 px-4 my-3"> */}
          {/* <Lorem /> */}
          <Routes>
            <Route path="why-rated10" element={<WhyRated10 />} />
            <Route path="why" element={<WhyRated10 />} />
            <Route path="for-creators" element={<AboutUsForCreators />} />
            <Route path="for-users" element={<AboutUsForEndUser />} />
            <Route path="mobile" element={<AboutUsMobile />} />
            <Route path="about-us" element={<AboutUsForCreators />} />
            <Route path="contact-support" element={<ContactSupport />} />
            <Route path="contact-us" element={<ContactSupport />} />
            <Route path="terms-conditions" element={<TermsConditions />} />
            <Route path="creator-terms-conditions" element={<CreatorTermsConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="return-policy" element={<ReturnPolicy />} />
            <Route path="delete-account" element={<DeleteAccountRequest />} />
            <Route path="sponsorship-apply" element={<ApplyForSponsorship />} />
            <Route path="apply-to-be-creator" element={<ApplyToBeCreatorSeparated />} />
            <Route path="faq" element={<FAQPage />} />
            <Route path="referral-program" element={<ReferralProgramPage />} />
            <Route index element={<AboutUsForCreators />} />
            {/* Decides which page is the main page */}
            {/* <Route path="about-us" element={<AboutUs />} /> */}
            <Route
              path="creators"
              // index
              element={<Creators searchParam={searchParam} />}
            />
            <Route
              path="all-creators"
              element={<CreatorsAll searchParam={searchParam} />}
            />
            {/* <Route index element={<Deneme />} /> */}
            {/* <Route index element={<InquiryDetailPage />} /> */}
            <Route path="creator" element={<CreatorPage />} />
            <Route path="creator-page-edit" element={<CreatorPageEdit />} />
            {/* <Route path="signup" element={<Signup />} /> */}
            <Route path="login" element={<Login />} />
            <Route path="twitch-login" element={<TwitchLogin />} />
            {/* <Route path="contact" element={<Contact />} /> */}
            <Route path="user-settings" element={<Settings />} />
            <Route path="user-profile" element={<UserProfile />} />
            <Route path="inquiry-detail-page" element={<InquiryDetailPage />} />
            <Route
              path="inquiries"
              element={<InquiryAndHubList loggedBy="user" pageKey={pageKey} />}
            />
            <Route
              path="creator-hub"
              element={
                <InquiryAndHubList loggedBy="creator" pageKey={pageKey} />
              }
            />
            <Route path="payment-hub" element={<PaymentHub />} />
            <Route path="payment-success" element={<PaymentSuccess />} />
          </Routes>
        </p>
        <ConditionalFooter />
      </BrowserRouter>
      <BrowserRouter basename={'accountlink'}>
        <Routes>
              <Route path="reauth" element={<AccountLinkReauth />} />
              <Route path="return" element={<AccountLinkReturn />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

function ConditionalFooter() {
  const location = useLocation();

  useEffect(() => {
    // Log the current URL path to Google Analytics
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
  
  const showFooter =
    location.pathname === "/" ||
    location.pathname === "/about-us" ||
    location.pathname === "/for-creators" ||
    location.pathname === "/for-users" ||
    location.pathname === "/mobile" ||
    location.pathname === "/why-rated10" ||
    location.pathname === "/why" ||
    location.pathname === "/contact-support" ||
    location.pathname === "/privacy-policy" ||
    location.pathname === "/return-policy" ||
    location.pathname === "/terms-conditions" ||
    location.pathname === "/creator-terms-conditions" ||
    location.pathname === "/delete-account" ||
    location.pathname === "/sponsorship-apply" ||
    location.pathname === "/apply-to-be-creator" ||
    location.pathname === "/faq" ||
    location.pathname === "/referral-program" ||
    location.pathname === "/contact-us";

  return showFooter ? <R10Footer /> : null;
}

export default App;
