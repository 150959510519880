import React from "react";
import {isMobile} from "../utilities/Functions";

class AboutUsPromotionBanner extends React.Component {
    render() {
        const isMobileDevice = isMobile();

        const darkBlueColor = '#3B3F4E'
        const lightBlueColor = '#5D677F'
        const orangeColor = '#E35335'
        const sunsetOrange = '#FA5F55'
          
        const containerStyle = {
            paddingBottom: '10px',
            paddingTop: '0px',
            paddingLeft: isMobileDevice ? '10px' : '300px',
            paddingRight: isMobileDevice ? '10px' : '300px',
            boxSizing: 'border-box',
        };
    
        const titleStyle = {
            fontSize: isMobileDevice ? '16px' : '20px',
            fontWeight: 'bold',
            marginBottom: '0px',
            textAlign: 'left',
            color: 'white',
        };

        const textStyle = {
            marginTop: '5px',
            textAlign: 'left',
            fontSize: isMobileDevice ? '10px' : '12px',
            wordBreak: 'break-word',
            overflowWrap: 'break-word', // Ensure long words break and wrap
            color: 'white',
        };
    
        const boxContainerStyle = {
            display: 'flex',
            paddingLeft: isMobileDevice ? '10px' : '40px',
            flexDirection: isMobileDevice ? 'column' : 'row',
            flexWrap: 'wrap',
            gap: isMobileDevice ? '10px' : '20px',
            justifyContent: isMobileDevice ? 'center' : 'space-between',
            alignItems: isMobileDevice ? 'center' : 'flex-start',
            backgroundColor: 'blue',
          };
    
        const mobileBoxStyle = {
            flex: '1 1 45%',
            padding: '10px',
            border: '1px solid #FA5F55',
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: orangeColor,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '130px',
            boxSizing: 'border-box',
        };
    
        const boxStyle = {
            flex: 1,
            padding: '20px',
            border: '1px solid #FA5F55',
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: orangeColor,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px',
        };

        return (
            <section className="shake" id="how-it-works" style={containerStyle}>
            {isMobileDevice ? (
                <div style={mobileBoxStyle}>
                    <span style={titleStyle}>Limited Time Offer: Decreased Platform Charge to 10% until January 1st!</span>
                </div>
                ) : (
                <div style={boxStyle}>
                    <span style={titleStyle}>Limited Time Offer: Decreased Platform Charge to 10% until January 1st!</span>
                </div>
            )}
            </section>
        ); 
    }
}

export default AboutUsPromotionBanner;