import React from 'react';
import {Image, Button} from "react-bootstrap";
import {isMobile} from "../utilities/Functions";
import AboutUsCategories from '../components/AboutUsCategories';
import AboutUsReviews from '../components/AboutUsReviews';
import AboutUsHowItWorks from '../components/AboutUsHowItWorksForCreator.js';
import AboutUsProductDescription from '../components/AboutUsProductDescriptionForCreators.js';
import GetStartedMobile from '../components/GetStartedMobile.js';
import SponsorshipButton from '../components/SponsorshipButton.js';
import IntroductionPhoto from "../assets/IntroductionPhoto.png";
import DatingExample from "../assets/DatingExample.png";
import FinanceExample from "../assets/FinanceExample.png";
import FashionAndBeautyExample from "../assets/Fashion&BeautyExample.png";
import FeaturedCreators from "../components/FeaturedCreators.js";
import AboutUsPromotionBanner from '../components/AboutUsPromotionBanner.js';
import NewsletterSignup from '../components/NewsletterSignup.js';

const AboutUsMobile = () => {
  class Header extends React.Component {
    render() {
      return (
        <header className="App-header">
          <h1>Welcome to Rated10!</h1>
        </header>
      );
    }
  }

  class ProductVideo extends React.Component {
    render() {
      return (
        <section
          id="product-video"
          className="d-flex flex-column justify-content-center align-items-center text-center"
          style={{
            paddingBottom: isMobile() ? '60px' : '100px',
            paddingTop: '20px',
            paddingLeft: isMobile() ? '20px' : '40px',
            paddingRight: isMobile() ? '20px' : '40px',
            borderBottom: '1px solid #ddd',
            width: isMobile() ? '100%' : '33.33%', // 1/3 of the width
          }}
        >
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <div style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%' }}>
              <iframe
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                src="https://www.youtube.com/embed/KW1OIW71lZU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Product Video"
              ></iframe>
            </div>
          </div>
        </section>
      );
    }
  }

  class ExampleUseCases extends React.Component {
    render() {
      const useCases = [
        {
          title: 'Dating',
          image: DatingExample,
        },
        {
          title: 'Fashion & Beauty',
          image: FashionAndBeautyExample,
        },
        {
          title: 'Finance',
          image: FinanceExample,
        }
      ];

      const imageStyle = {
        height: isMobile() ? 'auto' : '520px', // Auto height for mobile to maintain aspect ratio
        width: isMobile() ? '100%' : 'auto', // 80% width for mobile, original width otherwise
        marginLeft: isMobile() ? '-10px' : '-80px', // Adjust margins for mobile and desktop
        marginTop: isMobile() ? '-70px' : '-100px',
        marginBottom: isMobile() ? '-40px' : '-80px',
        marginRight: isMobile() ? '-10px' : 'auto', // Ensure image is centered or aligned correctly
        // display: 'block', // Ensures the image behaves correctly within its container
      };

      // const imageStyle = {
      //   height: isMobile() ? 'auto' : '520px', // Auto height for mobile to maintain aspect ratio
      //   width: isMobile() ? '100%' : 'auto', // 100% for mobile, auto for desktop
      //   maxWidth: 'none', // Prevents the image from being constrained by the container's width
      //   marginLeft: '0px', // Adjust margin if needed
      //   marginTop: '0px', // Adjust margin for positioning
      //   marginBottom: '0px',
      //   marginRight: '0px',
      //   position: 'absolute', // Position the image absolutely to make it go beyond the container
      //   left: '50%', // Center the image horizontally
      //   transform: 'translateX(-50%)', // Shift the image to truly center it
      //   zIndex: 1, // Ensure it stays above other elements
      // };

      const darkBlueColor = '#3B3F4E'
      const lightBlueColor = '#5D677F'
      const orangeColor = '#E35335'

      return (
        <section
          id="use-cases"
          style={{ padding: '20px', paddingTop: '40px', paddingBottom: '40px', borderBottom: '1px solid #ddd' }}
        >
          <h2 style={{ fontSize: '2em', marginBottom: '40px' }}>Example Use Cases</h2>
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile() ? 'column' : 'row', // Stacks vertically for mobile, horizontally for desktop
              flexWrap: 'wrap',
              justifyContent: isMobile() ? 'center' : 'space-between', // Adjusts alignment based on screen size
            }}
          >
            {useCases.map((useCase, index) => (
              <div
                key={index}
                style={{
                  width: isMobile() ? '100%' : '30%', // Full width for mobile, 30% for desktop
                  padding: isMobile()? '0px' : '20px',
                  marginBottom: '20px',
                }}
              >
                <h3 style={{ fontSize: '1.5em', marginBottom: '30px' }}>
                  {useCase.title === 'Dating' || useCase.title === 'Finance' || useCase.title === 'Fashion & Beauty' ? (
                    <span style={{ color: darkBlueColor, fontStyle: 'italic' }}>{useCase.title}</span>
                  ) : (
                    useCase.title
                  )}
                </h3>
                <Image src={useCase.image} alt="Usecase image" style={imageStyle} />
              </div>
            ))}
          </div>
        </section>
      );
    }
  }

  function setPageLayout() {
    const introPhotoStyle = {
      height: isMobile() ? '40vh' : '100vh',
      marginRight: isMobile() ? '0px' : '10px',
      // position: 'absolute', // Absolute positioning to prevent container resizing
      left: isMobile() ? '50%' : '0px', // Center horizontally for mobile, original position for desktop
      top: isMobile() ? '0px' : '-60px',
      zIndex: isMobile() ? 'auto' : 1, // Make sure the image stays on top
      width: 'auto', // Maintain aspect ratio
      // transform: isMobile() ? 'translateX(-50%)' : 'none', // Translate image to center on mobile
    };
    
    return (
      <div className="App">
        {/* <Header /> */}
        <AboutUsPromotionBanner />
        {/* <SponsorshipButton /> */}
        <div className="d-flex" style={{ display: 'flex', flexDirection: isMobile() ? 'column-reverse' : 'row', alignItems: 'stretch', justifyContent: 'center', width: '100%' }}>
          <AboutUsProductDescription />
          <div style={{ 
            position: isMobile() ? 'static' : 'relative', // Allow image to extend beyond container
            overflow: 'visible',  // Make sure container doesn't clip the overflowing image
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            width: isMobile() ? '100%' : '40%' // Full width for mobile, 40% for desktop 
          }}>
            <Image 
              src={IntroductionPhoto} 
              alt="Introduction Photo" 
              style={{ 
                ...introPhotoStyle, 
                position: isMobile() ? 'static' : 'relative', 
                zIndex: isMobile() ? 'auto' : 1 // Make sure it stays on top
              }} 
            />
          </div>
        </div>
        <ExampleUseCases />
        <AboutUsHowItWorks />
        <AboutUsCategories />
        <FeaturedCreators />
        <AboutUsReviews />
        <NewsletterSignup />
        <GetStartedMobile />
      </div>
    );
  }
  return setPageLayout();
};

export default AboutUsMobile;
